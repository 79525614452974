body {
  margin: 0;
  font-family: Work Sans, sans-serif !important;
}

p {
  margin: 0;
}

h1 {
  font-family: Montserrat, sans-serif !important;
  /* font-weight: 700 !important; */
}

h2 {
  font-family: Montserrat, sans-serif !important;
  /* font-weight: 700 !important; */
}

h3 {
  font-family: Montserrat, sans-serif !important;
  /* font-weight: 700 !important; */
}

h4 {
  font-family: Montserrat, sans-serif !important;
  /* font-weight: 700 !important; */
}

h5 {
  font-family: Montserrat, sans-serif !important;
  /* font-weight: 700 !important; */
}

h6 {
  font-family: Montserrat, sans-serif !important;
  /* font-weight: 700 !important; */
}

html {
  font-size: 0.875rem !important;
}

@media only screen and (max-width: 599px) {
  html {
    font-size: 0.75rem !important;
  }
}

@media only screen and (min-width: 1536px) {
  html {
    font-size: 1rem !important;
  }
}

@media only screen and (min-width: 1920px) {
  html {
    font-size: 1.125rem !important;
  }
}

@media only screen and (min-width: 2560px) {
  html {
    font-size: 1.25rem !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #2B2A2A;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: 0;
}

/* Basic editor styles */
.tiptap {
  background-color: white;
  max-height: 20rem;
  overflow: hidden;
  overflow-y: scroll;

  padding: 1.5rem;
  margin-bottom: 1.5rem;

  :first-child {
    margin-top: 0;
  }

  p {
    font-family: Work Sans, sans-serif !important;
  }

  /* List styles */
  ul, 
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;
    
    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  /* Heading styles */
  h1, 
  h2, 
  h3, 
  h4, 
  h5, 
  h6 {
    font-family: Montserrat, sans-serif !important;
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
  }

  h1, 
  h2 {
    font-family: Montserrat, sans-serif !important;
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }

  h1 { 
    font-family: Montserrat, sans-serif !important;
    font-size: 1.4rem; 
  }

  h2 { 
    font-family: Montserrat, sans-serif !important;
    font-size: 1.2rem; 
  }

  h3 { 
    font-family: Montserrat, sans-serif !important;
    font-size: 1.1rem; 
  }

  h4, 
  h5, 
  h6 { 
    font-family: Montserrat, sans-serif !important;
    font-size: 1rem; 
  }

  /* Code and preformatted text styles */
  code {
    background-color: var(--purple-light);
    border-radius: 0.4rem;
    color: var(--black);
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }

  pre {
    background: var(--black);
    border-radius: 0.5rem;
    color: var(--white);
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  blockquote {
    border-left: 3px solid var(--gray-3);
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid var(--gray-2);
    margin: 2rem 0;
  }
}

.ProseMirror:focus {
  outline: none;
}

.ProseMirror {
  font-family: Work Sans, sans-serif !important;
  font-size: 1rem;
  line-height: 1.5;
  min-height: 20rem;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  border: 1px solid rgba(0, 156, 164, 0.4);
  border-top: none;
}

.ProseMirror[contenteditable="false"] {
  background-color: #f8f8f8;
  cursor: not-allowed;
}